<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <el-button class="right" type="text" @click="$router.back()">返回</el-button>
      </div>
      <el-form :model="searchForm" :inline="true">
        <el-date-picker v-model="searchForm.month" type="month" :clearable="false" @change="getByAbarnTestingTime"
          class="mgB10 custom-form-input" placeholder="请选择月份">
        </el-date-picker>
        <el-tooltip :value="true" content="点击关闭 tooltip 功能" placement="top" effect="light" manual popper-class="pd0">
          <div slot="content">
            <el-button type="text" @click="changeHouse(0)">上一仓</el-button>
            <el-button class="mgL10" type="text" @click="changeHouse(1)">下一仓</el-button>
          </div>
          <el-select v-model="searchForm.houseId" placeholder="请选择仓房" @change="getByAbarnTestingTime"
            class="mgL10 mgB10 custom-form-input">
            <el-option v-for="(item,index) in cangList" :key="index" :label="item.houseNo" :value="item.locaId">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip :value="true" content="点击关闭 tooltip 功能" placement="top" effect="light" manual popper-class="pd0">
          <div slot="content">
            <el-button type="text" @click="changeTemps(0)">上一条</el-button>
            <el-button class="mgL10" type="text" @click="changeTemps(1)">下一条</el-button>
          </div>
          <el-select v-model="searchForm.id" placeholder="请选择测温数据" class="mgL10 mgB10 custom-form-input">
            <el-option v-for="(item,index) in tempList" :key="index" :label="item.tempTime" :value="item.id">
            </el-option>
          </el-select>
        </el-tooltip>
        <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
        </el-button>
        <el-button type="info" class="mgL10 mgB10" @click="detailVisible=true">详情</el-button>
        <el-button type="success" class="mgL10 mgB10" @click="compareVisible=true">对比</el-button>
        <el-button type="primary" @click="exportExcel" class="mgL10 mgB10">导出</el-button>
        <el-button type="primary" @click="printTable" class="mgL10 mgB10">打印</el-button>
      </el-form>
      <PaneData ref="paneData" :loading="loading" :tempInfo="tempInfo" @cellDblclick="cellDblclick" />
    </el-card>
    <!-- 数据详情 -->
    <el-dialog class="custom-dialog" title="数据详情" :visible.sync="detailVisible" width="520px" :append-to-body="true">
      <TempDetail :tempInfo="tempInfo" />
    </el-dialog>
    <!-- 数据对比 -->
    <el-dialog class="custom-dialog" title="数据对比" :visible.sync="compareVisible" width="380px" :append-to-body="true">
      <Compare :houseId="searchForm.houseId" :state="form.isCompare" @close="this.compareVisible=false"
        @confirm="confirmCompare" />
    </el-dialog>
  </div>
</template>

<script>
  var moment = require('moment');
  export default {
    components: {
      TempDetail: () => import('./components/TempDetail'),
      Compare: () => import('./components/Compare'),
      PaneData: () => import('@/components/PaneData'),
    },
    data() {
      return {
        loading: false,
        searchForm: {
          month: new Date(),
          id: "",
          houseId: ''
        },
        form: {
          id: '',
          month: '',
          isCompare: false
        },
        cangList: [],
        tempList: [],
        tempInfo: {},
        detailVisible: false,
        compareVisible: false
      }
    },
    created() {
      this.searchForm = {
        month: moment(this.$route.query.time).format('YYYY-MM'),
        id: Number(this.$route.query.id),
        houseId: Number(this.$route.query.houseNoId)
      }
      this.testingTempByIdTable();
      this.getAbarnNames();
      this.getByAbarnTestingTime()
    },
    methods: {
      //单元格双击事件
      cellDblclick(e) {
        this.$router.push({
          path: "/analysis",
          query: {
            spot: e.cellData,
            houseNo: this.tempInfo.locaId,
            time: this.tempInfo.testingTime
          }
        })
      },
      //搜索
      search() {
        this.testingTempByIdTable();
      },
      //对比提交
      confirmCompare(e) {
        this.form.id = e.id
        this.form.isCompare = e.isCompare
        this.testingTempByIdTable();
        this.compareVisible = false
      },
      //打印
      printTable() {
        let routeData = this.$router.resolve({
          path: "/print/Pane",
          query: {
            id: this.tempInfo.tempId,
            compareId: this.form.isCompare ? this.form.id : ''
          }
        });
        window.open(routeData.href, '_blank');
      },
      //导出
      exportExcel() {
        let param = {}
        if (this.form.isCompare) param['compareID'] = this.form.id
        this.$axios.Get(this.$api.testingTempExcleId + this.searchForm.id, param).then(res => {
          window.open(this.$api.BaseUrl + "file/down-temp-xls/" + res.data.downName + "?fileName=" + res.data
            .fileName + "&code=" + res.data.code);
        })
      },
      //仓房更改  0 上 1下
      changeHouse(type) {
        let current = this.cangList.findIndex(item => item.locaId === this.searchForm.houseId)
        if (current !== -1) {
          if (type === 0) {
            if (current === 0) {
              this.$message({
                message: '这已经是第一条数据了',
                type: 'warning'
              });
              return;
            }
            this.searchForm.houseId = this.cangList[current - 1].locaId
          } else {
            if (current >= this.cangList.length - 1) {
              this.$message({
                message: '这已经是最后一条数据了',
                type: 'warning'
              });
              return;
            }
            this.searchForm.houseId = this.cangList[current + 1].locaId
          }
          var than = this;
          this.getByAbarnTestingTime(function(res) {
            let currentTemp = res.findIndex(item => item.id === than.searchForm.id)
            if (currentTemp === -1 && res.length > 0) {
              than.searchForm.id = res[0].id
              than.testingTempByIdTable()
            }
          })
        }
      },
      //更给测温数据  0 上 1下
      changeTemps(type) {
        let current = this.tempList.findIndex(item => item.id === this.searchForm.id)
        if (current !== -1) {
          if (type === 0) {
            if (current === 0) {
              this.$message({
                message: '这已经是第一条数据了',
                type: 'warning'
              });
              return;
            }
            this.searchForm.id = this.tempList[current - 1].id
          } else {
            if (current >= this.tempList.length - 1) {
              this.$message({
                message: '这已经是最后一条数据了',
                type: 'warning'
              });
              return;
            }
            this.searchForm.id = this.tempList[current + 1].id
          }
          this.testingTempByIdTable()
        }
      },
      //获取仓房信息
      getAbarnNames() {
        this.$axios.Get(this.$api.abarnNames, {}).then(res => {
          this.cangList = res.data
        })
      },
      //获取测温数据
      async getByAbarnTestingTime(fun) {
        let res = await this.$axios.Get(this.$api.byAbarnTestingTime, {
          "locaId": this.searchForm.houseId,
          "month": moment(this.searchForm.month).format('YYYY-MM')
        })
        this.tempList = res.data
        if (fun && typeof fun === "function") fun(res.data)
      },
      //获取数据
      testingTempByIdTable() {
        this.loading = true
        let param = {}
        if (this.form.isCompare) param['compareID'] = this.form.id
        this.$axios.Get(this.$api.testingTempByIdTable + this.searchForm.id, param).then(res => {
          this.tempInfo = res.data
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style>
</style>
